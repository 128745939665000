
import { FormPage } from '@/mixins'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import AdminZoneModel from 'tradingmate-modules/src/models/api/admin/zones/AdminZoneModel'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import AdminMovePostcodeModel from 'tradingmate-modules/src/models/api/admin/zones/AdminMovePostcodeModel'
import Modal from '@/components/actions/Modal.vue'
import { ZoneSelector } from '@/components/inputs'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader,
    Modal,
    ZoneSelector
  }
})

export default class AdminZoneEdit extends Mixins(FormPage) {
  private zone: AdminZoneModel | null = null
  private postcodeModelActive = false;
  private removeZoneModelActive = false;
  private movePostcodeModel: AdminMovePostcodeModel = { ToZoneId: '', Postcode: '' }

  mounted (): void {
    this.getZone()
  }

  get hasPostcodes (): boolean {
    if (!this.zone) return true
    return this.zone.Postcodes.length > 0
  }

  getZone (): void {
    const zoneId = this.$route.params.id
    Services.API.Admin.Zones.GetZone(zoneId)
      .then((returnModel) => { this.zone = returnModel })
  }

  // CreatePostCode (): void {
  //   if (!this.zone) return
  //   Services.API.Admin.Zones.AddPostcode(this.zone.ZoneId, this.addPostcodeModel)
  //     .then((returnModel) => (
  //       this.zone?.Postcodes.push(returnModel.Postcode)
  //     )).then(() => {
  //       this.postcodeModelActive = false
  //       this.addPostcodeModel.Postcode = ''
  //     })
  // }

  handleZoneSelections (zoneId: string[]): void {
    this.movePostcodeModel.ToZoneId = zoneId[0]
  }

  handleMovePostcode (postcode: string): void {
    this.movePostcodeModel.ToZoneId = ''
    this.movePostcodeModel.Postcode = postcode
    this.postcodeModelActive = true
  }

  movePostcode (): void {
    if (!this.zone) return
    Services.API.Admin.Zones.MovePostcode(this.zone.ZoneId, this.movePostcodeModel)
      .then((returnPostcode) => {
        if (!this.zone) return
        const idx = this.zone.Postcodes.findIndex((element) => element === returnPostcode.Postcode)
        this.zone.Postcodes.splice(idx, 1)
        this.postcodeModelActive = false
      })
  }

  handleDeleteZone (): void {
    if (!this.zone) return
    Services.API.Admin.Zones.DeleteZone(this.zone.ZoneId)
      .then((returnBool) => {
        if (returnBool) {
          this.$router.push('/admin/zones')
        }
      })
  }
}

